import React, { FunctionComponent } from "react"
import { graphql, PageProps } from "gatsby"
import * as SCM from "../types"
import Home from "../components/Home"
import Layout from "../components/Layout"
import SEO from "../components/SEO"

type Props = PageProps & {
  data: {
    homePage: SCM.HomePage
  }
}

const HomeTemplate: FunctionComponent<Props> = props => {
  const homePage = props.data.homePage

  return (
    <Layout>
      <SEO locale={homePage.locale} uri={props.uri} {...homePage.seo} />
      <Home {...homePage} />
    </Layout>
  )
}

export default HomeTemplate

export const query = graphql`
  query HomePageQuery {
    homePage {
      ...homePageFragment
    }
  }
`
